import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="container">
      <h1>404</h1>

      <p>
        <strong>Page not found :(</strong>
      </p>
      <p>The requested page could not be found.</p>
    </div>
  </Layout>
)

export default NotFoundPage
